import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { Api } from '@/_api/decorators/api/Api'

export interface IAllowedRes {
  allowed: boolean
}

export const getIPPrimaryLink = () => gon.application.get_ip_primary
export const getIPReserveLink = () => gon.application.get_ip_reserve

export class NetworkMonitorAPI {
  @Api.notify('deleteMessage', 'doctor', { success: false, failure: false })
  @Api.delete(Routes.destroy_user_session_path)
  logout () {
    return {} as ICRUDReturn as Promise<void | IApiErrors>
  }

  @Api.notify('fetchMessage', 'ipAddress', { success: false, failure: false })
  @Api.get(getIPPrimaryLink)
  getIPPrimary () {
    return {} as ICRUDReturn as Promise<string | IApiErrors>
  }

  @Api.notify('fetchMessage', 'ipAddress', { success: false, failure: false })
  @Api.get(getIPReserveLink)
  getIPReserve () {
    return {} as ICRUDReturn as Promise<string | IApiErrors>
  }

  @Api.notify('checkMessage', 'ipAddress', { success: false, failure: false })
  @Api.post(Routes.check_api_internal_white_lists_path)
  checkIP (ip = '') {
    if (!ip) { return }

    return {
      payload: {
        ip,
      },
    } as ICRUDReturn as Promise<IAllowedRes | IApiErrors>
  }
}
