import TurbolinksAdapter from 'vue-turbolinks'
import ElementUI from 'element-ui'
import tooltipDirective from '@/plugins/vue/tooltipDirective'
import updateSync from '@/plugins/vue/updateSync'
import routes from '@/plugins/vue/routes'
import permissions from '@/plugins/vue/permissions'
import { skeletonDirective } from '@/plugins/vue/skeletonDirective/skeletonDirective'
import VueTheMask from 'vue-the-mask'
import { registerValidator } from '@/plugins/vue/registerValidator'
import { vueFiltersPlugin } from '@/plugins/vue/vueFiltersPlugin'
import { medodsPlugin } from '@/plugins/vue/medodsPlugin/medodsPlugin.ts'
import { utilsPlugin } from '@/plugins/vue/utilsPlugin'

export const registerVueUtils = (Vue) => {
  Vue.use(medodsPlugin)
  Vue.use(vueFiltersPlugin)
  Vue.use(updateSync)
  Vue.use(TurbolinksAdapter)
  Vue.use(ElementUI, {
    size: 'small',
  })
  Vue.use(routes)
  Vue.use(permissions)
  Vue.use(VueTheMask)
  Vue.directive('tooltip', tooltipDirective)
  Vue.directive('skeleton', skeletonDirective)
  Vue.use(registerValidator)
  Vue.use(utilsPlugin)

  Vue.directive('el-select-lazy', {
    bind (el, binding) {
      /* если обернуть m-select-lazy, то перестаёт работать скроллинг из-за подсчета в условии для binding.value() */
      const SCROLL_BOTTOM_GAP = 2

      const selectWrapperDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
      selectWrapperDom.addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop - SCROLL_BOTTOM_GAP <= this.clientHeight) {
          binding.value()
        }
      })
    },
  })

  Vue.directive('focus', function (el, binding) {
    binding.value ? el.focus() : el.blur()
  })

  Vue.directive('click-outside', {
    bind (el, binding, vnode) {
      el.eventOnClick = function (event) {
        const dragging = el.getAttribute('data-dragging')
        if (!(el === event.target || el.contains(event.target)) && !dragging) {
          vnode.context[binding.expression](event)
        }
      }
      document.addEventListener('click', el.eventOnClick)
    },
    unbind (el) {
      document.removeEventListener('click', el.eventOnClick)
    },
  })
}
