import { debounce } from 'lodash'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { IConfirmIPConfig, NetworkMonitorAPIFacade } from '@/services/NetworkMonitor/NetworkMonitorAPIFacade'
import { NumberHelpers } from '@/helpers/NumberHelpers'

export class NetworkMonitor {
  protected API = new NetworkMonitorAPIFacade()

  protected confirmIP = debounce(this._confirmIP.bind(this), 5378)

  protected connection: {
    addEventListener: (arg0: string, arg1: () => void) => void
    removeEventListener: (arg0: string, arg1: () => void) => void
  }

  protected username: string

  protected interval: number

  protected dialogOpened: boolean = false

  protected checkInterval = 2

  constructor () {
    this.subscribeNetworkStatus()
    this.subscribeNavigatorConnection()
    this.subscribeSockets()
    this.subscribeInterval()
    this.username = gon.application.current_user.username
    this.checkInterval = NumberHelpers.clampToRange(gon.application.ipCheckInterval, 1, 60)
  }

  get isSystemUser () {
    return this.username === 'softs'
  }

  protected subscribeNetworkStatus () {
    window.addEventListener('online', this.confirmIP.bind(this))
    console.info('Отслеживание онлайн-статуса... ')
  }

  protected subscribeNavigatorConnection () {
    // @ts-ignore
    this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    if (!this.connection) {
      console.info('Network Information API не поддерживается в вашем браузере')

      return
    }

    if (!navigator.onLine || this.isSystemUser) {
      this.connection.addEventListener('change', this.confirmIP.bind(this))
      console.info('Отслеживание соединения... ')
    }
  }

  protected subscribeSockets () {
    Services.wsSubscriptions.ip.connect(({ action, data }) => {
      if (action !== 'ip_changed') { return }

      const addresses: string[] = data.addresses
      if (!this.API.ip || addresses.includes(this.API.ip)) { this.confirmIP({ force: true }) }
    })
  }

  protected subscribeInterval () {
    this.interval = window.setInterval(() => {
      this._confirmIP({ force: true })
    }, this.checkInterval * 1000 * 60)
  }

  protected async _confirmIP (config: IConfirmIPConfig = { force: false }) {
    const { allowed } = await this.API.confirmIP(config)
    if (allowed) { return this.ipOk() }
    await this.logout()
  }

  protected ipOk () {}

  protected async logout () {
    await this.API.logout()

    if (!this.dialogOpened) {
      this.dialogOpened = true
      await MConfirm.info(t('network_vue.notWhiteListed'), {
        showCancelButton: false,
        showClose: false,
        confirmButtonText: 'OK',
      })
    }

    this.unsubscribeNetworkStatus()
    this.unsubscribeNavigatorConnection()
    this.unsubscribeSockets()
    this.unsubscribeInterval()
    this.dialogOpened = false

    location.reload()
  }

  protected unsubscribeNetworkStatus () {
    window.removeEventListener('online', this.confirmIP.bind(this))
  }

  protected unsubscribeNavigatorConnection () {
    if (!this.connection) { return }
    this.connection.removeEventListener('change', this.confirmIP.bind(this))
    this.connection = null
  }

  protected unsubscribeSockets () {
    Services.wsSubscriptions.ip?.reset()
  }

  protected unsubscribeInterval () {
    window.clearInterval(this.interval)
  }
}
