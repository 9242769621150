import { IAllowedRes, NetworkMonitorAPI } from '@/services/NetworkMonitor/NetworkMonitorAPI'
import { IApiErrors } from '@/_declarations/IApiErrors'

export interface IConfirmIPConfig {
  force: boolean
}

export class NetworkMonitorAPIFacade extends NetworkMonitorAPI {
  #ipAddress = ''

  logout (): Promise<void | IApiErrors> {
    return super.logout()
  }

  get ip (): string { return this.#ipAddress }

  async confirmIP (config: IConfirmIPConfig = { force: false }): Promise<IAllowedRes> {
    const ipAddress = await this.getIP() || {}
    if (typeof ipAddress !== 'string') { return { allowed: false } }

    const useCachedIPAddress = !config.force
    if (useCachedIPAddress &&
        this.#ipAddress &&
        ipAddress === this.#ipAddress
    ) { return { allowed: true } }

    const allowedRes = await this.checkIP(ipAddress) || {}
    if ('errors' in allowedRes) { return { allowed: false } }

    const allowed = (allowedRes as IAllowedRes).allowed
    if (allowed) { this.#ipAddress = ipAddress }

    return allowedRes as IAllowedRes
  }

  async getIP () {
    const primary = await this.getIPPrimary()
    if (typeof primary === 'string') { return primary }

    const reserve = await this.getIPReserve()
    if (typeof reserve === 'string') { return reserve }
  }
}
