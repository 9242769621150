import Vue from 'vue/dist/vue.esm'
import ElementUI from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'
import printJS from 'print-js'
import Services from './services/service_repository_instance.ts'
import { REFUSED_CALL_RESULT } from './modules/telephony/const.js'
import Highcharts from 'highcharts'
import dataModule from 'highcharts/modules/data'
import tippy from 'tippy.js'
import { introductionModalBuilder } from './specific/introduction_modal'
import AddListenersForStubs from './specific/stubs/stub'
import { registerHandlebarsHelpers } from '@/_global_scripts/handlebars_helpers'
import { globalVuexStore } from '@/global_store'
import { registerVueUtils } from '@/_global_scripts/vue_utils'
import { createNavbarSelectClinic } from '@/vue_components/users/NavbarSelectClinic'
import { checkBrowser } from '@/_global_scripts/checkBrowser'
import { onLogout } from '@/_global_scripts/onLogout'
import { createLastAppointmentsStandalone } from '@/vue_components/appointment/LastAppointmentsStandalone'
import { NetworkMonitor } from '@/services/NetworkMonitor/NetworkMonitor'

// VUE USE AND DIRECTIVES
registerVueUtils(Vue)

dataModule(Highcharts)
tippy.setDefaultProps(Utils.tippyConfig)
locale.use(lang)
window.Emessage = ElementUI.Message // обновил для tipNotify.js
window.Econfirm = ElementUI.MessageBox.confirm

$document.on('turbolinks:load', function (e, params) {
  PubSub.clearSubscriptions('page')
  Turbolinks.clearCache()
  LastAppointments.clear()
  CallModule.clear()
  moment.locale(gon.localization.locale)

  window.Services = Services
  window.Localization = gon.localization

  window.printJS = printJS

  window.String.prototype.format = function (...args) {
    return this.replace(/%{(\d+)}/g, (m, n) => args[n] ? args[n] : m)
  }

  I18n.pluralization.ru = function (count) {
    const key =
      count % 10 === 1 && count % 100 !== 11
        ? 'one'
        : [2, 3, 4].indexOf(count % 10) >= 0 &&
        [12, 13, 14].indexOf(count % 100) < 0
            ? 'few'
            : count % 10 === 0 ||
          [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
          [11, 12, 13, 14].indexOf(count % 100) >= 0
              ? 'many'
              : 'other'

    return [key]
  }

  Vue.prototype.T = window.T = I18n.translations[gon.localization.locale]
  Vue.prototype.t = window.t
  Vue.prototype.$pubSub = Services.pubSub
  Vue.prototype.$routes = Routes

  checkBrowser()
  createNavbarSelectClinic()
  onLogout()

  Services.pubSub.resetAll()
  Services.wsSubscriptions.resetSubscriptions()
  Services.security.load(
    gon.application.security.models,
    gon.application.security.actions,
    gon.application.security.current_user_permissions
  )
  Services.ui.load()
  Services.telephony.init()
  Services.workplace.init()
  Services.clinicSwitch.init()
  Services.clientFeedbacks.init()

  if (gon.application.network_module_enabled &&
      gon.application.current_user &&
      gon.application.configuration_limit_by_ip &&
      navigator.onLine &&
      gon.application.current_user?.id !== 1
  ) {
    window.NetworkMonitor = new NetworkMonitor()
  }

  // HANDLEBARS HELPERS
  registerHandlebarsHelpers()

  moment.locale(gon.localization.locale)
  $.extend($.fn.datepicker.defaults, {
    language: gon.localization.locale,
    startDate: '01.01.1900',
    format: gon.application.date_format,
    weekStart: gon.localization.day_week_start,
  })

  if (!$.CategoriesX) {
    console.warn('?')
    console.warn($)
    console.warn(Utils)
  }

  $.EntryTypeSelector.clearCache()
  $.CategoriesX.clearDataCache()
  $.contextMenu('destroy')

  if (gon.application.current_user && gon.application.current_user.id > 1) {
    AppCache.store('user', gon.application.current_user)
    Notification.init(notificationInitializationCallback).then(() => {
      Notify.init()
    })
  }

  window.pageInfo = window.getControllerAction(e)
  window.application = {}
  window.application.current_clinic = gon.application.current_clinic
  window.application.plan = gon.application.plan
  const controller = pageInfo.controller
  let action = pageInfo.action
  const id = pageInfo.id
  const eventCore = 'rez'
  const actionEvent = eventCore + '/' + controller + '/' + action
  const controllerEvent = eventCore + '/' + controller

  window.queryParams = Utils.getQueryParams()

  if (id) {
    if (action === 'show') {
      action = ''
    }
    Rez.history.push('/' + controller + '/' + id + '/' + action)
  } else {
    Rez.history.push('/' + controller + '/' + action)
  }

  console.debug('action event', actionEvent)
  console.debug('controller event', controllerEvent)

  $document.trigger(controllerEvent)
  $document.trigger(actionEvent)

  // devise overwrite controller and action name, some bad code for fix it
  if (window.location.pathname === '/clients/sign_in') {
    $document.trigger(eventCore + '/clients/sign_in')
  }

  if (window.location.pathname === '/users/sign_in') {
    $document.trigger(eventCore + '/users/sign_in')
  }

  // autosize for all textareas
  jQuery(function ($) {
    $('.textarea_autosize').autosize()
  })

  HistoryBrowser.update()

  Utils.select2ButtonUpdate()
  $('.nav-tabs').stickyTabs()

  Utils.clickableTr()
  Utils.clickableBtn()

  MedodsClientHelper.update()

  const $externalSubmits = $('.external-submit')

  $externalSubmits.each(function () {
    const $this = $(this)
    const form = $($this.data('form'))
    form.find(':submit').hide()
  })

  $externalSubmits.on('click', function () {
    $($(this).data('form')).submit()
  })

  if (JobsQueue.length) {
    JobsQueue.shift()()
  }

  Utils.UISettings.init()
  Utils.LocalStorage.update()
  Utils.fixTabPagination()

  window.randomName = faker.name.firstName()

  $('.date input, input.date').each(function (i, e) {
    Services.ui.setInputDatepicker($(e))
  })

  $('#close_all_notifications').on('click', function () {
    $.notifyClose()
    $(this).hide()
  })

  $('input.birthdate ').inputmask('date', { placeholder: '__.__.____' })
  $('input.single_month').inputmask('date', 'mm.yyyy')

  $('#navbar-notifications').on('click', function () {
    Notification.openModal()
  })

  $('#notifications_container').on('click', '.notification-dismiss', function () {
    Notification.dismiss($(this).data('id'))
  })

  $('#notifications_container').on('click', '.notification-row_reminder-title', function () {
    const reminderID = $(this).data('reminder-id')
    Notification.openReminderForm(reminderID)
  })

  $('#notifications_modal').on('click', '.mark-all-as-read', function () {
    Notification.dismissAll()
  })

  Services.wsSubscriptions.notification.connect((notification) => {
    const currentUserID = gon.application.current_user.id

    if ($.isArray(notification)) {
      notification.forEach(function (ntf) {
        if (ntf.responsible_id === currentUserID) {
          Notification.update(ntf, notificationInitializationCallback)
        }
      })
    } else {
      if (notification.responsible_id === currentUserID) {
        if (notification.notification_type_id === null) {
          Notificator[notification.type](notification.message)
        } else {
          Notification.update(notification, notificationInitializationCallback)
        }
      }
    }
  })

  Services.wsSubscriptions.calls.connect((msg) => {
    const call = msg.data
    const currentUserID = gon.application.current_user.id
    const responsibles = call.responsibles.map((responsible) => responsible.id)

    if (responsibles.includes(currentUserID)) {
      if (call.refuse_result_id !== null) return

      Services.telephony.removeCallNotifications(call)
      Services.telephony.createCallNotification(call)
      if (call.result === REFUSED_CALL_RESULT) {
        // timeout needed for proper animation show
        setTimeout(function () {
          Services.telephony.createAskForResultNotification(call)
        }, 600)
      }
    } else {
      Services.telephony.removeCallNotifications(call)
    }
  })

  $('.select2-hidden-accessible').on('select2:open', function () {
    $('.select2-search__field').attr('placeholder', t('search'))
  })

  $('.smart-table').smartTable()

  $('.stop-propagation').on('click', function (e) {
    e.stopPropagation()
  })

  $('.turboform').turboform()

  $('.ofc-btn').onlineFontChanger()

  // var maskList = $.masksSort($.masksLoad(countries), ['#'], /[0-9]|#/, "mask");

  $.contextMenu(ContextMenu.clickableRow({
    items: ['openInNewWindow'],
    selector: '.clickable-row',
  }))

  // FreshDesk uses this object as cache
  if (window.FreshWidget) {
    window.$widget_attr = {}
    window.FreshWidget.update()
  }

  $('.feature_not_purchased').click(function (e) {
    e.preventDefault()
    bootbox.alert(t('feature_not_activated_contact_customer'))
  })

  $('.tablesorter').tablesorter({ widgets: ['staticRow'] })

  // hide prompt events
  $('.prompt-notice-container').promptNotice()

  const updateBodyPrintSwitch = (elem) => {
    const body = $('body')[0]
    const switchName = elem.data('switch')
    if (!switchName) return

    const { classList } = body
    const classes = [].slice.apply(classList)

    classes.forEach(function (cl) {
      if (!cl.match(/^switch-to-.*/)) return
      classList.remove(cl)
    })

    classList.add(`switch-to-${switchName}`)
  }

  $('.toggle-show-btn').click(function () {
    $('.toggle-print').addClass('toggle-show')
    updateBodyPrintSwitch($(this))
    window.print()

    return false
  })

  $('.toggle-hide-btn').click(function () {
    $('.toggle-print').removeClass('toggle-show')
    updateBodyPrintSwitch($(this))
    window.print()

    return false
  })

  $('.button-print').click(function () {
    window.print()

    return false
  })

  // change_clinic
  $('#clinic_select_form').remote_form({
    model: 'user',
    onSuccess (user) {
      Services.clinicSwitch.dispatchClinicSwitch(user.current_clinic_id)
      Services.clinicSwitch.refreshPage()
    },
    onError () {
      Services.clinicSwitch.UIunlock()
    },
  })

  $('#change_clinic').click(function (e) {
    e.stopPropagation()

    $('#select_clinic_modal').modal('show')
  })

  $('#user_current_clinic_id').change(function () {
    Services.clinicSwitch.UIlock()
    $('#clinic_select_form').submit()
  })

  $('.userDropdownTemplate').select2({
    templateResult: Utils.userDropdownTemplate,
    templateSelection: Utils.userDropdownTemplate,
  })

  $('.referralDropdownTemplate').select2({
    templateResult: Utils.referralDropdownTemplate,
    templateSelection: Utils.referralDropdownTemplate,
  })

  $('.referralSmartSearch').select2({
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    ajax: {
      url: Routes.search_referrals_path(),
      dataType: 'json',
      data (params) {
        return {
          title: params.term,
          source: 'select2_search',
        }
      },
      processResults (data) {
        return {
          results: data,
        }
      },
      cache: true,
    },
    templateResult: Utils.referralDropdownTemplateAjax,
    templateSelection: Utils.referralDropdownTemplateAjax,
  })

  $('.sort-date-period').daterangepicker(Utils.getDateRangePickerConfig({
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Очистить',
    },
  }))

  $('.sort-date-period').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('D MMMM YYYY') + ' - ' + picker.endDate.format('D MMMM YYYY'))
  })

  $('.sort-date-period').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('')
  })

  $('input').not('.autocomplete').attr('autocomplete', 'off')

  if (gon.application.current_clinic) {
    // crutch replacing logos of clinics for printing
    $('.clinic-header .logo-clinics-in-header')
      .attr('src', Routes.logo_clinic_path(gon.application.current_clinic))
  }

  function notificationInitializationCallback () {
    const messages = $('.notify-feedback-crutch')
    messages.off('click')
    messages.click((event) => {
      const feedbackId = $(event.target).data('feedback-id')
      Services.pubSub.emit('CLIENT_FEEDBACKS_MODAL.OPEN', feedbackId)
    })
  }

  Utils.restoreScrollPosition()
  Utils.setActiveTr()

  // 7jt1ev (10) - глобальная модалка для заглушек модулей
  // Как пользоваться:
  // 1) Utils.gIntroductionModal.icon = 'fa-your-icon'
  // 2) Utils.gIntroductionModal.innerText = 'ваш текст'
  // 3) Utils.gIntroductionModal.modalShow() - показать модалку
  // 7jt1ev (11)
  // ИЛИ
  // Utils.gIntroductionModal.setData(icon, innerText) - замена иконки и текста
  // ИЛИ
  // Utils.gIntroductionModal.setDataAndShow(icon, innerText) - замена иконки и текста + открытие модалки
  Utils.gIntroductionModal = introductionModalBuilder('js_global_introduction_modal', 'fa-globe', 'later')

  /**
   * bv0ewe
   * Добавление обработчиков на элементы,
   * при нажатии на которые вызываются модалки с заглушками
   */
  AddListenersForStubs(Utils.gIntroductionModal)

  ;(() => {
    const $scrollContainer = $('.app_configuration_list .list-index-body')

    if (store.get('app_configuration_ui') === undefined) {
      store.set('app_configuration_ui', {})
    }

    if (store.get('app_configuration_ui') && store.get('app_configuration_ui').scrollPosition) {
      $scrollContainer.scrollTo(
        store.get('app_configuration_ui').scrollPosition
      )
    }
    $scrollContainer.on('scroll', function () {
      store.set('app_configuration_ui', { scrollPosition: this.scrollTop })
    })
  })()

  // GLOBAL STORE
  setTimeout(() => { globalVuexStore() }, 0)

  if (gon.page.controller !== 'doctor_schedules') {
    createLastAppointmentsStandalone()
  }

  setTimeout(() => {
    Utils.updateTooltipsInstances(document)
    Utils.updateTooltips(document)
  }, 100)
})

//hehe
